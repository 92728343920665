/* eslint-disable  */

export default {
  'About us': 'Chi siamo',
  'Add new address': 'Aggiungi un nuovo indirizzo',
  'Add to compare': 'Aggiungi per confrontare',
  'Add to Wishlist': 'Aggiungi alla lista dei desideri',
  'Additional Information': 'Informazioni aggiuntive',
  'Allow order notifications': 'Consenti notifiche ordini',
  Apply: 'Applica',
  'Attention!': 'Attenzione!',
  'Back to home': 'Torna alla home page',
  'Back to homepage': 'Torna alla homepage',
  'Billing address': 'Indirizzo di fatturazione',
  Brand: 'Marca',
  Cancel: 'Annulla',
  Categories: 'Categorie',
  'Change password your account': 'Se desideri cambiare la password, compila i seguenti campi',
  Change: 'Cambia',
  'Clear all': 'Cancella tutto',
  Color: 'Colore',
  'Commercial information': 'accetto di ricevere informazioni commerciali personalizzate da Biffi via e-mail',
  'Fill all the required fields:': 'Compila tutti i campi richiesti:',
  'Contact us': 'Contattaci',
  'Continue to billing': 'Continua con la fatturazione',
  'Continue to payment': 'Continua al pagamento',
  'Continue to shipping': 'Continua con la spedizione',
  'Cookies Policy': 'Politica sui cookie',
  Customer: 'Cliente',
  'Customer Reviews': 'Recensioni dei clienti',
  'Customer service': 'Servizio clienti',
  Delete: 'Elimina',
  Departments: 'Dipartimenti',
  Description: 'Descrizione',
  'Details and status orders':
    'Controlla i dettagli e lo stato dei tuoi ordini nel negozio online. Puoi anche annullare il tuo ordine o richiedere un reso.',
  Discount: 'Sconto',
  Done: 'Fatto',
  'Download all': 'Scarica tutto',
  Download: 'Scarica',
  Edit: 'Modifica',
  'Email address': 'Indirizzo e-mail',
  Empty: 'Sembra che tu non abbia ancora aggiunto alcun oggetto alla borsa. Inizia a fare acquisti per riempirlo.',
  'Enjoy your free account': 'Goditi questi vantaggi con il tuo account gratuito!',
  Feedback: 'Il tuo feedback è importante per noi. Facci sapere cosa potremmo migliorare',
  'Feel free to edit': 'Qui puoi aggiornare i tuoi dati personali in qualsiasi momento.',
  Filters: 'Filtri',
  'Find out more': 'Scopri di più',
  'Forgot Password Modal Email': 'Email che stai usando per accedere:',
  'Forgot Password': 'Se non ricordi la password, puoi reimpostarla.',
  'Forgotten password?': 'Password dimenticata?',
  'Go back shopping': 'Torna a fare shopping',
  'Go back to shop': 'Torna al negozio',
  'Go back': 'Torna indietro',
  Guarantee: 'Garanzia',
  'Help & FAQs': 'Aiuto e FAQ',
  Help: 'Aiuto',
  hide: 'nascondere',
  Home: 'Casa',
  'I agree to': 'Accetto',
  'I confirm subscription': "Confermo l'iscrizione",
  'Info after order':
    "Puoi accedere al tuo account utilizzando e-mail e password definite in precedenza. Sul tuo account puoi modificare i dati del tuo profilo, controllare la cronologia delle transazioni, modificare l'iscrizione alla newsletter.",
  Instruction1: 'Prenditi cura di me',
  Instruction2: 'Solo qui per le istruzioni di cura?',
  Instruction3: 'Sì, lo pensavamo',
  'It was not possible to request a new password, please check the entered email address.':
    "Non è stato possibile richiedere una nuova password, si prega di controllare l'indirizzo e-mail inserito.",
  Item: 'Articolo',
  Items: 'Articoli',
  Kidswear: 'Abbigliamento per bambini',
  'Let’s start now – we’ll help you': 'Iniziamo ora, ti aiuteremo.',
  'Log into your account': 'Accedi al tuo account',
  'login in to your account': 'accedi al tuo account',
  Login: 'Accedi',
  'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.':
    'Sembra che tu non abbia ancora aggiunto alcun oggetto alla borsa. Inizia a fare acquisti per riempirlo.',
  'Make an order': 'Fai un ordine',
  'Here you can edit or add billing and shipping addresses to use during the checkout':
    'Qui puoi modificare o aggiungere gli indirizzi di spedizione da utilizzare in fase di acquisto:',
  'Manage billing addresses':
    "Gestisci tutti gli indirizzi di fatturazione che desideri (posto di lavoro, indirizzo di casa...) In questo modo non dovrai inserire manualmente l'indirizzo di fatturazione con ogni ordine.",
  'Manage shipping addresses':
    "Gestisci tutti gli indirizzi di spedizione che desideri (posto di lavoro, indirizzo di casa...) In questo modo non dovrai inserire manualmente l'indirizzo di spedizione con ogni ordine.",
  'Match it with': 'Abbinalo a',
  'Men fashion': 'Moda uomo',
  'Gender': 'Genere',
  'My Cart': 'Il mio carrello',
  'No account': 'Non hai ancora un account?',
  'On Hold': 'In elaborazione',
  'or fill the details below': 'o compila i dettagli qui sotto',
  "Don't forget to enter the code FASHION20 at checkout!": "Non dimenticare di inserire il codice FASHION20 al checkout!",
  'Order No.': "Numero d'ordine",
  'Order summary': "Riepilogo dell'ordine",
  'Other products you might like': 'Altri prodotti che potrebbero piacerti',
  'Password Changed': 'Password modificata correttamente. Ora puoi tornare alla home page e accedere.',
  'Pay for order': 'Paga per ordine',
  'Payment & Delivery': 'Pagamento e consegna',
  'Payment method': 'Metodo di pagamento',
  'Payment methods': 'Metodi di pagamento',
  Payment: 'Pagamento',
  'Personal details': 'Dati personali',
  'Please type your current password to change your email address.': 'Digita la password corrente per modificare il tuo indirizzo email.',
  Price: 'Prezzo',
  'Privacy Policy': 'Informativa sulla privacy',
  Privacy: 'Privacy',
  'Product suggestions': 'Suggerimenti sui prodotti',
  Product: 'Prodotto',
  'Products found': 'Prodotti trovati',
  Products: 'Prodotti',
  'Purchase terms': 'Condizioni di acquisto',
  'Quality in the details': 'Qualità nei dettagli',
  Quantity: 'Quantità',
  'Read all reviews': 'Leggi tutte le recensioni',
  'Read and understand': "Ho letto e capito l'",
  'Read reviews': 'Leggi le recensioni',
  'Register today': 'Registrati oggi',
  'Register here': 'Registrati qui',
  Register: 'Registrati',
  'Remove from Wishlist': 'Rimuovi dalla lista dei desideri',
  'Reset Password': 'Reimposta password',
  'Review my order': 'Rivedi il mio ordine',
  'Same as shipping address': "Uguale all'indirizzo di spedizione",
  'Save changes': 'Salva modifiche',
  'Save for later': 'Salva per dopo',
  'Save Password': 'Salva password',
  'Search for items': 'Cerca articoli',
  'Search results': 'Risultati della ricerca',
  'Sections that interest you': 'Sezioni che ti interessano',
  'See all results': 'Vedi tutti i risultati',
  'Select payment method': 'Seleziona metodo di pagamento',
  'Select shipping method': 'Seleziona il metodo di spedizione',
  'Send my feedback': 'Invia il mio feedback',
  'Set up newsletter': 'Spunta la casella qui sotto per essere sempre aggiornato sulle nostre novità e promozioni.',
  'Share your look': 'Condividi il tuo look',
  'Shipping address': 'Indirizzo di spedizione',
  'Shipping details': 'Dettagli di spedizione',
  'Shipping method': 'Metodo di spedizione',
  'show more': 'mostra di più',
  'Show on page': 'Mostra sulla pagina',
  'Sign in': 'Accedi',
  'Sort by': 'Ordina per',
  'Sort: Default': 'In evidenza',
  'Sort: Name A-Z': 'Nome A-Z',
  'Sort: Name Z-A': 'Nome Z-A',
  'Sort: Price from high to low': 'Prezzo decrescente',
  'Price from high to low': 'Prezzo decrescente',
  'Sort: Price from low to high': 'Prezzo crescente',
  'Price from low to high': 'Prezzo crescente',
  'Sort: New arrivals': 'Novità',
  'Start shopping': 'Inizia a fare shopping',
  'Subscribe to newsletter': 'Iscriviti alla newsletter',
  Subscribe: 'Iscriviti',
  Subtotal: 'Subtotale',
  'Successful placed order':
    "Hai effettuato con successo l'ordine. Puoi controllare lo stato del tuo ordine utilizzando la nostra funzione di stato della consegna. Riceverai un'e-mail di conferma dell'ordine con i dettagli del tuo ordine e un link per monitorarne l'avanzamento.",
  'Terms and conditions': 'Termini e condizioni',
  'Thank You Inbox': 'Se il messaggio non arriva nella tua casella di posta, prova un altro indirizzo email che potresti aver usato per registrarti.',
  'Total items': 'Totale articoli',
  'Total price': 'Prezzo totale',
  Total: 'Totale',
  'Update password': 'Aggiorna password',
  'Update personal data': 'Aggiorna i dati',
  'Use your personal data':
    "Attribuiamo grande importanza alle questioni relative alla privacy e ci impegniamo a proteggere i dati personali dei nostri utenti. Scopri di più su come ci prendiamo cura e utilizziamo i tuoi dati personali nell' ",
  'User Account': 'Account utente',
  'View details': 'Visualizza dettagli',
  View: 'Vista',
  'Who we are': 'Chi siamo',
  'Women fashion': 'Moda donna',
  'You can unsubscribe at any time': "Puoi annullare l'iscrizione in qualsiasi momento",
  'You currently have no orders': 'Al momento non hai ordini',
  'You haven’t searched for items yet': 'Non hai ancora cercato oggetti.',
  'Your bag is empty': 'La tua borsa è vuota',
  'Your current email address is': 'Il tuo indirizzo email attuale è',
  forgotPasswordConfirmation:
    "Grazie! Se c'è un account registrato con l'e-mail {0}, troverai un messaggio con un link per la reimpostazione della password nella tua casella di posta.",
  subscribeToNewsletterModalContent:
    'Dopo esserti iscritto alla newsletter, riceverai offerte speciali e messaggi da VSF via e-mail. Non venderemo o distribuiremo la tua e-mail a terzi in nessun momento. Si prega di consultare il nostro {0}.',
  'Do you want to contact us?': 'Vuoi contattarci?',
  'Fill out the form below!': 'Compila il form qui sotto!',
  'Come visit': 'Vieni a trovarci',
  'In our stores, as well as online, we welcome women from different generations. From mothers to their young daughters.':
    'Nei nostri negozi, così come online, accogliamo donne di generazioni diverse. Dalle mamme alle loro giovani figlie.',
  'Follow us and stay updated': 'Seguici e rimani aggiornato',
  'FOLLOW US ON INSTAGRAM': 'SEGUICI SU INSTAGRAM',
  'Required field': 'Campo obbligatorio',
  Name: 'Nome',
  'Phone Number': 'N. Telofono',
  Message: 'Messaggio',
  'Send Message': 'Invia messaggio',
  'La nostra storia': 'La nostra storia',
  'Siamo una piccola azienda di famiglia: abbiamo forti legami con il tessuto produttivo del nostro paese ma ispirazioni molto internazionali.':
    'Siamo una piccola azienda di famiglia: abbiamo forti legami con il tessuto produttivo del nostro paese ma ispirazioni molto internazionali.',
  'Fatto con amore': 'Fatto con amore',
  'in Italia.': 'in Italia.',
  'Key years of our company which has moved from a production for third parties to the production and direct sale of our brand.':
    'Anni chiave della nostra azienda che si è indirizzata da una produzione per conto terzi alla produzione e alla vendita diretta di un nostro marchio.',
  'The workshop moves to a colonial house in the center of the town. Current headquarters of our company in San Bonifacio.':
    'Il laboratorio si sposta in una casa coloniale al centro del paese. Attuale sede della nostra azienda a San Bonifacio.',
  'Laboratory born in the province of Vicenza, starting to work for big fashion brands, producing quality raincoats and shrugs.':
    'Laboratorio nato in provincia di Vicenza, iniziando a lavorare per grandi marchi della moda, producendo impermeabili e coprispalle di qualità.',
  'Our product is the result of a lot of love for our job and we believe in the true Made in Italy, understood as the packaging of clothing made with precious raw materials: the choice of the fabrics we use is made taking into account the origin of the same, with a preference for materials whose printing and finishing are made in Italy in compliance with sustainability regulations.':
    'Il nostro prodotto è frutto di tanto amore per il nostro mestiere e crediamo nel vero Made in Italy, inteso come confezione di capi di abbigliamento realizzati con materie prime pregiate: la scelta dei tessuti che utilizziamo è fatta tenendo conto della provenienza degli stessi, con una predilezione per i materiali la cui stampa e finissaggio sono realizzati in Italia nel rispetto delle normative di sostenibilità.',
  'Find out shipping costs': 'Scopri costi di spedizione',
  'Worldwide delivery in 2/7 working days.': 'Consegna in tutto il mondo in 2/7 giorni lavorativi.',
  'Thank you for your order!': 'Grazie per il tuo ordine!',
  'Your Purchase': 'Il tuo acquisto',
  'Your Account': 'Il tuo account',
  'You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.':
    "Hai effettuato correttamente l'ordine. Puoi controllare lo stato del tuo ordine utilizzando la nostra funzione di stato di consegna. Riceverai un'e-mail di conferma dell'ordine con i dettagli del tuo ordine e un link per seguirne lo stato di avanzamento.",
  'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, of transactions, edit subscription to newsletter.':
    "Puoi accedere al tuo account utilizzando l'e-mail e la password definite in precedenza. Sul tuo account puoi modificare i dati del tuo profilo, delle transazioni, modificare l'iscrizione alla newsletter.",
  Contacts: 'Contatti',
  'Back to home page': 'Torna alla home page',
  'Description product': 'Descrizione prodotto',
  'Let yourself be guided by our advice': 'Lasciati guidare dai nostri consigli',
  'Here is a list of related garments': 'Ecco un elenco di capi correlati',
  Carrello: 'Carrello',
  Yes: 'Sì',
  'Are you sure you want to remove this item from your shopping bag?': 'Sei sicuro di voler rimuovere questo articolo dalla shopping bag?',
  'Acquista ': 'Acquista ',
  'Torna allo shopping': 'Torna allo shopping',
  "It looks like you haven't added any items to the bag yet. Start shopping to fill it out.":
    'Sembra che tu non abbia ancora aggiunto alcun articolo al carrello.',
  Sizes: 'Taglia',
  'Size specifications': 'Specifiche delle dimensioni',
  'Do you want to be notified if the sizes will be available again?': 'Vuoi essere avvisato se le taglie saranno nuovamente disponibili?',
  'Add to cart': 'Aggiungi al carrello',
  'added to cart': 'aggiunto al carrello',
  'Add to shopping bag': 'Aggiungi alla shopping bag',
  'you discover': 'scopri',
  'Made with love in Italy': 'Fatto con amore in Italia',
  'Our garments are made with precious raw materials and carefully chosen paying attention to their origin in compliance with the rules on sustainability.':
    'I nostri capi sono realizzati con materie prime pregiate e attentamente scelte facendo attenzione alla loro provenenienza nel rispetto delle regole sulla sostenibilità.',
  'Discover ours': 'Scopri la nostra',
  'Discover our wool': 'Scopri la nostra lana',
  'Discover the new winter sweaters': 'Scopri i nuovi maglioni invernali',
  'The novelty of the moment!': 'La novità del momento!',
  'Our recommendations': 'I nostri consigli',
  Loading: 'Caricamento',
  'Sort by relevance': 'Ordina per rilevanza',
  'Apply filters': 'Applica filtri',
  'Something went wrong during form submission. Please try again later':
    "Qualcosa è andato storto durante l'invio del modulo. Per favore riprova più tardi",
  Billing: 'Fatturazione',
  'Street name': 'Nome della strada',
  'House/Apartment number': 'Numero civico',
  City: 'Città',
  'Zip-code': 'Cap',
  'Phone number': 'Numero di telefono',
  'Please select a country first': 'Seleziona prima un paese',
  'This field is required': 'Questo campo è obbligatorio',
  'Create an account on the store': 'Crea un account',
  'Login on the store': 'Ho già un account',
  Safety: 'Sicurezza',
  'It carefully packaged with a personal touch': 'Imballato con cura con un tocco personale',
  'Easy shipping': 'Spedizione facile',
  'You’ll receive dispatch confirmation and an arrival date': 'Riceverai la conferma della spedizione e una data di arrivo',
  'Changed your mind?': 'Hai cambiato idea?',
  'Rest assured, we offer free returns within 30 days': 'Stai tranquillo, offriamo resi gratuiti entro 30 giorni',
  'Copy address data from shipping': "Copia i dati dell'indirizzo dalla spedizione",
  Search: 'Cerca',
  'State/Province': 'Stato/Provincia',
  Country: 'Paese',
  'The field should have at least 2 characters': 'Il campo deve contenere almeno 2 caratteri',
  Monday: 'Lunedì',
  'Tuesday - Saturday': 'Da martedì a Sabato',
  'Tuesday - Friday': 'Da martedì a Venerdì',
  Saturday: 'Sabato',
  Sunday: 'Domenica',
  'Go to cart': 'Vai al carrello',
  'Enter promo code': 'Inserisci il codice promozionale',
  'Order review': "Riepilogo dell'ordine",
  'Select your Country': 'Seleziona un paese',
  'Remember me': 'Ricordami',
  'Please tick the boxes below to give your consent': 'Spunta le caselle qui sotto per dare il tuo consenso',
  'I want to create an account': 'Voglio creare un account',
  'Your email': 'La tua email',
  'Your e-mail': 'Email',
  'First Name': 'Nome',
  'Personal data': 'Dati personali',
  'Password change': 'Cambio password',
  'My Account': 'Il mio account',
  'Personal Details': 'Informazioni account',
  'My profile': 'Informazioni account',
  'Addresses details': 'Indirizzi',
  'Manage the addresses': 'Gestisci gli indirizzi',
  'Newsletter and profiling': 'Newsletter e profilazione',
  'Order details': 'Dettagli degli ordini',
  'Logout': 'Esci',
  'Current Password': 'Password Attuale',
  'New Password': 'Nuova Password',
  and: 'e',
  'and the': 'e la',
  'All Orders': 'Tutti gli ordini',
  Order: 'Ordine',
  Date: 'Data',
  Status: 'Stato',
  Amount: 'Totale',
  Taxes: 'Tasse',
  Excluded: 'Escluse',
  Included: 'Incluse',
  'Payment date': 'Data di pagamento',
  'Payment Date': 'Data di pagamento',
  'Order Date': 'Data di ordine',
  'My orders': 'I miei ordini',
  'Order list': 'Lista Ordini',
  'Insert your email address': 'Inserisci il tuo indirizzo email',
  'Subscribe to the newsletter': 'Iscriviti alla newsletter',
  'Please enter a valid email address.': 'Si prega di inserire un indirizzo email valido.',
  'Contacts Us': 'Contattaci',
  Services: 'Servizi',
  Shops: 'Negozi',
  Social: 'Social',
  'Add to favourites': 'Aggiungi ai preferiti',
  Share: 'Condividi',
  Remove: 'Rimuovere',
  Cart: 'Carrello',
  'Manage address': 'Gestisci gli indirizzi',
  'Order history': 'Ordini',
  'Manage addresses': 'Qui puoi modificare o aggiungere gli indirizzi di spedizione da utilizzare in fase di acquisto:',
  'Add the address': "Aggiungi l'indirizzo",
  'Save the address': "Salva l'indirizzo",
  'Update the address': "Aggiorna l'indirizzo",
  'Set as default shipping': 'Imposta come spedizione predefinita',
  'Set as default billing': 'Imposta come fatturazione predefinita',
  'The field should have at least {length} characters': 'Il campo deve contenere almeno {length} caratteri',
  "Passwords don't match": 'Le password non corrispondono',
  'Passwords do not match': 'Le password non corrispondono',
  'The user account data was successfully updated!': "I dati dell'account utente sono stati aggiornati correttamente!",
  'This feature is not implemented yet! Please take a look at': "Questa funzione non è ancora implementata! Si prega di dare un'occhiata",
  'for our Roadmap!': 'per la nostra tabella di marcia!',
  Returns: 'Ritorna',
  'Last Name': 'Cognome',
  'Invalid email': 'E-mail non valida',
  'Use this address as my default one.': 'Usa questo indirizzo come quello predefinito.',
  'Default Shipping Address': 'Indirizzo di spedizione predefinito',
  'Default Billing Address': 'Indirizzo di fatturazione predefinito',
  'Select a shipping address': 'Seleziona un indirizzo di spedizione',
  'Use this address': 'Utilizza questo indirizzo',
  'Customer care: +39 045 7614582': 'Servizio clienti: +39 045 7614582',
  'Usually arrives in 5-13 business days. A shipping timeline specific to your destination can be viewed in Checkout.':
    'Di solito arriva in 5-13 giorni lavorativi. Una sequenza temporale di spedizione specifica per la tua destinazione può essere visualizzata in Checkout.',
  'Your cart is empty': 'Il tuo carrello è vuoto',
  'Looks like you haven’t added any items to the cart yet. Start shopping to fill it in.':
    'Sembra che tu non abbia ancora aggiunto alcun articolo al carrello. Inizia a fare acquisti per compilarlo.',
  Password: 'Password',
  'The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)':
    'La password deve essere lunga almeno 8 caratteri e deve contenere almeno: 1 lettera maiuscola o minuscola, 1 numero o un carattere speciale (Es. , . _ & ? ecc.)',
  'Our story': 'La nostra storia',
  'Your shopping cart is empty': 'Il tuo carrello è vuoto',
  'Search Results': 'Risultati di ricerca',
  'Discover More': 'Scopri di più',
  'Subscribe to our newsletter': 'Iscriviti alla nostra newsletter',
  'Order By': 'Ordina per',
  'Visit us': 'Vieni a trovarci',
  'Need Help?': 'Serve Aiuto?',
  'Fill out the form below and we will reach back to you!': 'Compila il form qui sotto, ti contatteremo al più presto!',
  'OUR STORY': 'LA NOSTRA STORIA',
  'We are a small family-run business with a strong bond with our country.':
    'Siamo una piccola azienda di famiglia con forti legami con il tessuto produttivo del nostro paese.',
  'The page you were looking for cannot be found': 'La pagina che stai cercando non esiste',
  'If you typed the URL directly, please make sure the spelling is correct. If you clicked on a link to get here, the link is outdated.':
    "Se hai digitato direttamente l'URL, assicurati che l'ortografia sia corretta. Se hai cliccato su un link per arrivare qui, il link è obsoleto.",
  'You can also click the following links to get you back on track!': 'Puoi anche fare clic sui seguenti collegamenti per rimetterti in carreggiata!',
  'Your cart': 'Il tuo carrello',
  'Go to checkout': "Procedi all'acquisto",
  'Bank transfer': 'Bonifico',
  'Cash on delivery': 'Contrassegno',
  Check: 'Assegno',
  'No products were found': 'Non sono stati trovati prodotti',
  'Wrong user credentials': 'Credenziali utente errate',
  'Email was not found, not available or token is expired. Please request a new password.':
    'Email non trovata, non disponibile o token scaduto. Per favore richiedi una nuova password.',
  'successfully removed from your cart': 'è stato rimosso con successo dal carrello.',
  'Are you sure? you want to remove this item from your cart?': 'Sei sicuro di voler rimuovere questo articolo dal carrello?',
  'Ship to an address': 'Spedisci ad un indirizzo',
  'Pickup from UPS Access Point™ location': 'Ricevi presso un UPS Access Point™',
  Notes: 'Note',
  'Unable to select payment method, please try again later': 'Non è possibile selezionare il metodo di pagamento, si prega di riprovare più tardi',
  Continue: 'Continua',
  'Not found': 'Non trovato',
  'Use another card': "Usa un'altra carta",
  'Use another account': 'Usa un altro account',
  'Unable to process payment information, please try again':
    'Non è stato possibile verificare le informazioni per il pagamento, si prega di riprovare',
  'Card type not supported. Please try with another card enabled for 3D Secure':
    'Carta non supportata. Si prega di riprovare con una carta abilitata per 3D Secure',
  'The size and color must be selected before adding a product to the cart!':
    'La taglia e il colore devono essere selezionati prima di aggiungere un prodotto al carrello!',
  'Out of stock': 'Non disponibile',
  'Italian size': 'Taglia italiana',
  'Size guide': 'Guida alle taglie',
  'Notify me if back in stock': 'Vuoi essere avvisato se questo articolo tornerà disponibile?',
  Details: 'Descrizione',
  Shipping: 'Spedizione',
  'Shipments are made by UPS courier and are normally dispatched within 48 hours. Only in exceptional cases (for example during the sales period) it may take 3-4 business days to prepare the shipment.':
    'Le spedizioni sono effettuate con corriere UPS e vengono normalmente evase entro 48 ore. Solo in casi eccezionali (per esempio durante il periodo dei saldi) possono essere necessari 3-4 giorni lavorativi per preparare la spedizione.',
  'On average, delivery in Italy takes place 24-48 hours after the shipment, whilst it may take 2-6 business days for Europe and other countries.':
    'Per le consegne in Italia la spedizione è gratuita per importi pari o superiori a 100€, mentre per importi inferiori è previsto un contributo di 5€.La consegna avviene mediamente 24-48 ore dopo la spedizione dell’ordine.',
  'Shipping status can be verified at any time by using the tracking number provided via mail.':
    'Con la mail di conferma dell’ordine verrà fornito un codice di tracciamento per poter seguire la spedizione.',
  'Would you like to discover all about payments and returns? Have a look here. ("here" linkato a pagina customer care)':
    'Vuoi dare un’occhiata ai metodi di pagamento accettati o alla policy relativa a cambi e resi? Puoi trovare tutto qui. ("qui" linkato a pagina customer care)',
  'Get inspired We think you might love these': 'Lasciati ispirare Pensiamo che questi capi potrebbero piacerti',
  'Sign up for our newsletter': 'Iscriviti alla nostra newsletter',
  'By clicking “subscribe”, I agree that my information can be used for marketing segmentation.':
    'Cliccando su “Iscriviti”, acconsento che le mie informazioni vengano utilizzate per profilazione marketing.',
  'Re-Password': 'Re-Password',
  'Get inspired': 'Lasciati ispirare',
  'We think you might love these': 'Pensiamo che questi capi potrebbero piacerti',
  'Confirmation request has been sent.': 'La richiesta di conferma è stata inviata.',
  'The order has been paid with a gift card': "L'ordine è stato pagato con una gift card",
  'Enter gift card code': 'Inserisci il codice della gift card',
  'Paid with gift card': 'Pagato con gift card',
  'Gift card applied successfully.': 'Gift card applicata correttamente.',
  'Unable to apply gift card': 'Impossibile applicare la gift card',
  'An error occurred': 'Si è verificato un errore',
  'An error occurred during 3D Secure authentication': "Si è verificato un errore durante l'autenticazione 3D Secure",
  'No 3D Secure detected. Please consult your card issuer': "Non è stata rilevata l'autenticazione 3D Secure. Consultare l'emittente della carta",
  'Payment canceled': 'Pagamento cancellato',
  'please retry. If the problem persist, you can': 'ti invitiamo a riprovare. Se il problema persiste, puoi',
  'create a new cart': 'creare un nuovo carrello',
  'Your cart is outdated and must be reloaded': 'Il tuo carrello non è più valido e deve essere ricaricato',
  Reload: 'Ricarica',
  'My billing and shipping address are the same': "Usa l'indirizzo di spedizione anche per la fatturazione",
  'You reached the maximum saleable quantity': 'Hai raggiunto la massima quantità acquistabile',
  'Your request was submitted successfully; we will get back to you as soon as possible.':
    'La tua richiesta è stata inviata con successo; sarai ricontattato appena possibile.',
  'Request sent': 'Richiesta inviata',
  'Reset filters': 'Cancella Filtri',
  'This field cannot contain a semicolon (;)': 'Questo campo non può contenere un punto e virgola (;)',
  'The email format is not correct': 'Il formato email non è corretto',
  Select: 'Seleziona',
  'I agree with the handling of my data by this website in accordance with the':
    'Acconsento al trattamento dei miei dati personali secondo quanto descritto nella ',
  'It is necessary to consent in order to proceed': 'È necessario dare il consenso per proseguire',
  'biffi.com | Luxury Fashion | Clothing, Bags and Shoes': 'biffi.com |  Moda Lusso | Abbigliamento, Borse e Scarpe',
  'Editorial | biffi.com': 'Editoriale | biffi.com',
  loading: 'caricamento',
  Story: 'La storia',
  Boutique: 'Boutique',
  'Work with us': 'Lavora con noi',
  Company: 'Azienda',
  Support: 'Supporto',
  'Shipping and returns': 'Spedizioni e resi',
  'Track an order': 'Traccia un ordine',
  Faq: 'Domande frequenti',
  Payments: 'Pagamenti',
  'My account': 'Il mio account',
  'Follow us': 'Seguici',
  Man: 'Uomo',
  Women: 'Donna',
  'Select your country and language': 'Scegli il tuo paese e la lingua',
  'EN - English': 'IT - Italiano',
  'Insert here your email': 'Inserisci qui la tua mail',
  'I have read and agree to the': 'Ho preso visione e accetto la',
  'Sign up for our Newsletter': 'Iscriviti alla nostra newsletter',
  'Sign up to receive the latest news and exclusive offers from Biffi Boutiques and receive 10% off your first order':
    'Registrati per ricevere le ultime notizie e offerte esclusive di Biffi Boutiques e ricevi il 10% di sconto sul tuo primo ordine',
  'Latest Arrivals': 'Ultimi arrivi',
  'View all': 'Vedi tutti',
  'Free shipping on orders over €200': 'Spedizione gratuita per ordini oltre € 200',
  'Read the article': 'Leggi l’articolo',
  'I have read and agree to the ': `Ho letto e accetto l'Informativa sulla`,
  Editorial: 'Editoriale',
  'The tradition of the new': 'La tradizione del nuovo',
  "The restyling of Biffi Boutiques Group's store in Porta Genova has been completed, designed as a perfect balance of Milanese tradition and cosmopolitan taste.":
    'È stato completato il restyling dello store del gruppo Biffi Boutiques a Porta Genova, pensato come un perfetto equilibrio fra tradizione meneghina e gusto cosmopolita.',
  'Log in your account:': 'Accedi al tuo profilo personale:',
  Woman: 'Donna',
  'I agree with the handling of my data by this website in order to receive commercial or promotional communications through a newsletter':
    'Acconsento al trattamento dei miei dati personali per ricevere comunicazioni commerciali o promozionali, tramite la newsletter.',
  'I agree with the handling of my data by this website for profiling activities, for example the creation of personalized contents or offers.':
    'Acconsento al trattamento dei miei dati personali per attività di profilazione, ovvero per la creazione di contenuti e offerte personalizzate.',
  'Have an account already?': 'Hai già un account?',
  'There was an error while trying to login. Are your credentials correct?': 'Errore in fase di login. Le credenziali sono corrette?',
  'There was an error while trying to sign you up. Please try again.': 'Errore in fase di registrazione. Per favore riprova.',
  'The user password was changed successfully updated!': 'Password modifica con successo!',
  'The current password is not valid': 'La password attuale non è corretta',
  'Save as default shipping address': 'Salva come indirizzo di spedizione predefinito',
  'Save as default billing address': 'Salva come indirizzo di fatturazione predefinito',
  'Street Name': 'Indirizzo',
  'Contact details updated': 'Compila tutti i campi richiesti:',
  'Duties and taxes included': 'Dazi e tasse inclusi',
  'Duties and taxes excluded': 'Dazi e tasse esclusi',
  'Error while saving address.': "Si è verificato un errore durante il salvataggio dell'indirizzo.",
  'Care and details': 'Dettagli e cura',
  'Size and fit': 'Taglia e vestibilità',
  'Delivery and returns': 'Consegna e rimborsi',
  Composition: 'Composizione',
  'Made in': 'Prodotto in',
  Code: 'Codice prodotto',
  'last available': 'ultimo disponibile',
  'Select a size': 'Seleziona una taglia',
  'Select the size': 'Seleziona la taglia',
  'Loading sizes': 'Caricamento taglie',
  'Discover the latest arrivals in the Boutique': 'Scopri gli ultimi arrivi nella Boutique',
  details: 'dettagli',
  Open: 'Aperto',
  Pending: 'In elaborazione',
  Confirmed: 'Confermato',
  Shipped: 'Spedito',
  Failed: 'Fallito',
  Complete: 'Consegnato',
  Canceled: 'Cancellato',
  Refunded: 'Rimborsato',
  'Pending Payment': 'Pagamento in elaborazione',
  helpMessage: 'Siamo qui per rispondere alle tue domande. Contattaci via telefono oppure email:',
  of: 'di',
  orders: 'ordini',
  'show more results': 'mostra più risultati',
  'Ask for assistance': 'Chiedi assistenza',
  'Ask for a return': 'Effettua un reso',
  'Do you need help?': 'Hai bisogno di aiuto?',
  'Purchased Products': 'Prodotti acquistati',
  'Read more': 'Mostra di più',
  'Read less': 'Mostra meno',
  'Sort the products': 'Ordina i prodotti',
  'New arrivals': 'Novità',
  Default: 'In evidenza',
  'Show cart': 'Mostra carrello',
  'Proceed to checkout': 'Procedi al checkout',
  'Remove ': 'Rimuovi',
  'You currently have no items in your cart.': 'Al momento non hai articoli nel carrello.',
  "doesn't have the quantity requested": 'non ha la quantità richiesta',
  'quantity in cart was changed': 'la quantità nel carrello è stata modificata',
  'Other categories': 'Altre categorie',
  'Show more results': 'Mostra più risultati',
  products: 'prodotti',
  'Fiscal code': 'Codice fiscale',
  'May only contain alpha-numeric characters': 'Può contenere solo caratteri alfanumerici',
  'Must be longer than than {0} characters': 'Deve contenere almeno {0} caratteri',
  'Must be shorter than {0} characters.': 'Può contenere al massimo {0} caratteri',
  'telephone is not valid.': 'Numero di telefono non valido',
  'Your order number is: ': "Il tuo numero d'ordine è: ",
  'Thank you!': 'Grazie!',
  'You will soon receive an email at ': "Riceverai a breve un'email all'indirizzo ",
  'you specified during checkout': "specificato durante il checkout",
  'with the order summary and purchase details. We are preparing your order and will send a notification as soon as it will be ready to be shipped.':
    "con il riepilogo dell'ordine e i dettagli dell'acquisto. Stiamo preparando l'ordine e ti invieremo una notifica quando sarà pronto per la spedizione.",
  'Keep shopping': 'Continua lo shopping',
  'Go back to your Shopping Bag': 'torna alla shopping bag',
  'Contact us: support@biffi.com': 'Contattaci: support@biffi.com',
  'Choose your language': 'Seleziona la tua lingua',
  'You can contact us by email (support@biffi.com) or call us at (+39) 02 83116036':
    'Se ti serve aiuto puoi contattarci tramite mail (support@biffi.com) oppure puoi chiamarci al numero +39 02 83116036',
  'Your message': 'Il tuo messaggio',
  Submit: 'Invia',
  'Fill out the form to contact us for information, orders, shipping, returns or write to support@biffi.com':
    'Compila la form per contattarci per informazioni, ordini, spedizioni, resi oppure scrivi a support@biffi.com',
  'Our Customer Service is active Monday through Friday from 9 a.m. to 6 p.m. Phone: 02 83116036 - temporarily answering service':
    'Il nostro Servizio Clienti è attivo dal lunedì al venerdì dalle 9.00 alle 18.00. Telefono: 02 83116036 - momentaneamente servizio di segreteria',
  'Sorry, there are no results that match your search.': 'Siamo spiacenti, non ci sono risultati che soddisfino la tua ricerca.',
  '/woman': '/donna',
  '/man': '/uomo',
  'Results ': 'Risultati ',
  'No results': 'Nessun risultato',
  'Trending products': 'Prodotti di tendenza',
  'Start the shopping': 'Continua ad acquistare',
  'You currently have no items in your wishlist.': 'Al momento non ha prodotti in wishlist',
  'Your wishlist is empty': 'La tua wishlist è vuota',
  'Our Customer Service is active Monday through Friday from 9 a.m. to 6 p.m. Phone: +39 02 83116036 - temporarily answering service':
    'Il nostro Servizio Clienti è attivo dal lunedì al venerdì dalle 9.00 alle 18.00. Telefono: +39 02 83116036 - momentaneamente servizio di segreteria',
  'An error occurred while sending your request, please contact us directly at support@biffi.com':
    "Si è verificato un errore durante l'invio della richiesta, vi preghiamo di contattarci direttamente all'indirizzo support@biffi.com",
  'An error occurred, please try with different payment method or later.':
    'Si è verificato un errore, riprova con un altro metodo di pagamento o più tardi',
  'Card Number': 'Numero carta',
  'Expiration Date': 'Data di scadenza',
  CVV: 'CVC',
  'Choose your country': 'Seleziona la tua località',
  'Catalog and services may vary depending on the selected country.': 'Il catalogo e i servizi possono variare in base al paese selezionato',
  Confirm: 'Conferma',
  'Size:': 'Taglia:',
  'login or sign up': 'accedi o registrati',
  'Login or Sign Up': 'Accedi o Registrati',
  or: 'o',
  'Do you have a coupon code?': 'Hai un codice coupon?',
  'Proceed as guest': 'Continua come ospite',
  'Your email (ex. johndoe@email.com)': 'La tua Mail (es. johndoe@email.com)',
  'Your Last Name': 'Il tuo Cognome',
  'Your Name': 'il tuo Nome',
  'Your Address': 'Il tuo Indirizzo',
  'Street number': 'Numero Civico',
  Postcode: 'C.A.P',
  Region: 'Provincia',
  'Save current address': 'Salva indirizzo corrente',
  Street: 'Via/Strada/Piazza',
  Reason: 'Motivo del reso',
  Comments: 'Commenti',
  'Return Information': 'Informazioni per il reso',
  'Return to order': "Torna all'ordine",
  'The passwords must match.': 'Le password devono corrispondere.',
  'sale ': 'in saldo ',
  ' for ': ' da ',
  'Luxury Fashion': 'brand alta moda',
  "Sorry, we couldn't subscribe you to our newsletter. Please contact us at support@biffi.com!":
    'Ci dispiace, non siamo riusciti ad iscriverti alla newsletter! Per favore contattaci alla mail support@biffi.com',
  'Your subscription was successful!': 'Sei stato iscritto con successo!',
  'Please retry.': 'Ti invitiamo a riprovare',
  'Status:': 'Stato:',
  'Return request for order': "Richiesta di reso per l'ordine",
  'Product (refund no longer available)': 'Prodotto (reso non più disponibile)',
  'Unable to create return: please try later.': 'Impossibile effettuare il reso: riprovare più tardi.',
  'Return created successfully.': 'Richiesta di reso inviata con successo.',
  'You are not authorized, please log in.': 'La sessione è scaduta, effettua il login.',
  'You were unsubscribed successfully.': 'La tua iscrizione alla newsletter è stata rimossa con successo.',
  'Load more': 'Carica altri',
  'All products loaded': 'Tutti i prodotti caricati',
  'Your privacy is important find out how we protect your personal data in our Privacy Policy':
    'La tua privacy è importante scopri come proteggiamo i tuoi dati personali nella nostra Privacy Policy',
  'Date of birth': 'Data di nascita',
  Phone: 'Telefono',
  'Repeat Password': 'Ripeti password',
  'Create an account': 'Crea un account',
  'Create an account:': 'Crea un account:',
  'Hello,': 'Ciao,',
  'was added to your cart': 'è stato aggiunto al carrello',
  'is not available': 'non è disponibile',
  size: 'taglia',
  Subcategory: 'Sottocategorie',
  Size: 'Taglia',
  'Remove the check to unsubscribe': 'Rimuovi il check per annullare la sottoscrizione',
  'ALL DESIGNERS | MEN': 'TUTTI I DESIGNER | UOMO',
  'ALL DESIGNERS | WOMEN': 'TUTTI I DESIGNER | DONNA',
  'Unable to apply coupon': "Errore durante l'applicazione del coupon",
  'Applied Coupon': 'Coupon applicato',
  'Coupon removed successfully': 'Coupon rimosso correttamente',
  'All the transactions and information about the credit card are processed using SSL/TLS communication products, guaranteeing an always coded communication.':
    'Tutte le transazioni e le informazioni sulla carta di credito vengono elaborate utilizzando prodotti di comunicazione SSL/TLS, garantendo una comunicazione sempre codificata.',
  'You will receive a dispatch confirmation with your tracking number.': 'Riceverai una conferma di spedizione con il tuo numero di tracciamento.',
  Category: 'Categorie',
  "Can't find your country?": 'Non trovi il tuo paese?',
  'Click here': 'Clicca qui',
  'The inserted code is not valid. Please check it.':
    'Il codice digitato non è valido. Controlla quanto inserito.',
  'You are already subscribed to the newsletter. for support contact us at support@biffi.com!':
    'Sei già iscritto alla newsletter. per assistenza contattaci a support@biffi.com!',
  'Sorry, only Latin characters are allowed.': 'Ci dispiace, sono permessi soltanto caratteri Latini.',
  'Maximum 120 characters': 'Massimo 120 caratteri',
  'I am interested in receiving news and other offers related to fashion for': "Sono interessato a novità e offerte riguardante l'abbigliamento",
  'I changed my mind': 'Ho cambiato idea',
  'Wrong Size': 'Taglia sbagliata',
  'Product does not match the description': 'Articolo diverso dalla descrizione',
  'Damaged Item': 'Articolo arrivato danneggiato',
  'Color does not meet my expectations': 'Colore diverso dalle aspettative',
  'Too tight': 'Troppo stretto',
  'Too loose': 'Troppo grande',
  Other: 'Altro',
  'We\'re sorry, but Versace cannot be sold in India, please contact support@biffi.com': 'Siamo spiacenti, ma Versace non può essere venduto in India, per favore contatta support@biffi.com',
  'We\'re sorry, but Fendi cannot be sold in your country, please contact support@biffi.com': 'Siamo spiacenti, ma Fendi non può essere venduto nel tuo paese, per favore contatta support@biffi.com',
  'May only contain alphabetic characters': 'Può contenere solo caratteri alfabetici',
  'You seem to be connected from ': 'Sembra tu sia connesso in ',
  'Do you wish to switch country?': 'Vuoi cambiare paese?',
  'Italy': 'Italia',
  'One Size': 'Taglia unica',
  'ONE SIZE': 'TAGLIA UNICA',
  'Registration is reserved for users over 18 years of age': "L'iscrizione è riservata ad utenti maggiorenni",
  BySubscribing: 'Iscrivendoti accetti i nostri',
  Politics: 'e la nostra politica sulla',
  ' and to receive personalized commercial emails from us.': ' e di ricevere informazioni commerciali personalizzate dal marchio via e-mail.',
  'You have successfully subscribed/unsubscribed from our newsletter and/or from profiling' : 'Ti sei correttamente iscritto/disiscritto dalla nostra newsletter e/o dal profiling',
  'Please add your fiscal code': 'Per favore inserisci il tuo codice fiscale',
  'Modify': 'Modifica',
  'Name: ': 'Nome: ',
  'Your Data': 'I Tuoi Dati',
  'This page gives you a summary of recent activity and your account details' : 'Questa pagina ti offre un riepilogo delle attività recenti e dei dettagli del tuo account',
  'Welcome back, ': 'Ti diamo il benvenuto, ',
  'Overview': 'Panoramica',
  'Personal data and profiling': 'Dati personali e profilazione',
  'Check the box below to give your consent to profiling activities, content and personalized offers': 'Spunta la casella qui sotto per  dare il tuo consenso ad attvita di profilazione, contenuti e offerte personalizzate',
  'I give my consent': 'Do il mio consenso',
  'Your preferences have been correctly updated': 'le tue preferenze sono state aggiornate correttamente',
  'You have successfully unsubscribed from our newsletter': "L'iscrizione alla nostra newsletter è stata annullata con successo",
  'I consent to the processing of my personal data for profiling activities, i.e. for the creation of personalized content and offers.': 'Acconsento al trattamento dei miei dati personali per attività di profilazione, ovvero per la creazione di contenuti e offerte personalizzate.',
  'Spunta le caselle qui sotto per dare il tuo consenso': 'Please tick the boxes below to give your consent',
  'Billing Address': 'Indirizzo di fatturazione',
  'Shipping Address': 'Indirizzo di spedizione',
  'Discount percentage': 'Percentuale sconto',
  'Not discounted': 'Non scontato',
  'SEARCH DESIGNER': 'CERCA DESIGNER',
  'NO MORE PRODUCTS TO SHOW' : 'NON CI SONO PIÙ PRODOTTI DA MOSTRARE',
  'Sitemap' : 'Mappa del sito',
  'Find all the high fashion brands and designers on this map. You will be able to explore the selections and find all the categories of the site' : 'Trova tutti i brand di alta moda e i designer in questa mappa. Potrai esplorare le selezioni e trovare tutte le categorie del sito',
  'designers' : 'designer',
  'There is no filters available for the products currently shown, try to reset the filters applied' : 'Non ci sono filtri disponibili per i prodotti attualmente mostrati, provare a cancellare i filtri applicati',
  'Filter products' : 'Filtri prodotto',
  'See results' : 'Vedi risultati',
  'Oops!': 'Ops!',
  'The page you are looking for does not exist.': 'La pagina che stai cercando non esiste.',
  'If you typed the URL directly, make sure the spelling is correct. If you clicked on a link to get here, the link is outdated.': 'Se hai digitato direttamente l\'URL, assicurati che l\'ortografia sia corretta. Se invece hai cliccato su un link per arrivare qui, il link è obsoleto.',
  'You can click on the following links to browse the website correctly!': 'Puoi fare clic sui seguenti collegamenti per tornare a navigare correttamente il sito!',
  'we also recommend these products': 'ti suggeriamo anche questi prodotti',
  'Continue navigating': 'Continua a navigare',
  'Change country': 'Cambia paese',
  "We're sorry, but the following items are no longer available and were removed from your cart:": 'Siamo spiacenti, ma i seguenti articoli non sono più disponibili e sono quindi stati rimossi dal carrello:',
  'Click again to proceed to checkout.': 'Clicca di nuovo per procedere al checkout',
  'Already Returned': 'Già reso',
  'Send Request': 'Invia Richiesta',
  'Subscribe Now': 'Iscriviti Ora',
  'Sign up to receive the latest news and exclusive offers from Biffi Boutiques and receive 10% off your first order placed online at biffi.com.': 'Registrati per ricevere le ultime notizie e offerte esclusive di Biffi Boutiques e ricevi il 10 % di sconto sul tuo primo ordine effettuato online su biffi.com.',
  'Enter your Email': "Inserisci l'Email",
  'Enter your First Name': 'Inserisci il Nome',
  'Enter your Last Name': 'Inserisci il Cognome',
  'Insert a valid date': 'Inserisci una data valida',
  'I am interested in news and offers regarding clothing': "Sono interessato a novità ed offerte riguardante l'abbigliamento",
  'Having read the customer and user': "Presa visione dell'",
  'I consent to receive informational and promotional communications via the contact methods I have chosen, which I can change at any time.': 'acconsento a ricevere comunicazioni informative e promozionali medianti le modalità di contatto che ho scelto e che potrò modificare in qualunque momento.'
};
